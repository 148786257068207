import React from 'react';

import { Container, Image, Carousel } from 'react-bootstrap';

import "./ImageCarousel.css";


interface ImageCarouselProps {
    imagesSrc: string[];
    imageIntervals?: number[];
    background?: boolean;
    controls?: boolean;
    fade?: boolean;
    shadow?: boolean;
};

const ImageCarousel = ({
    imagesSrc,
    imageIntervals,
    background,
    controls,
    fade,
    shadow
}: ImageCarouselProps): JSX.Element => {
    const carouselClass = background ? "backgroundCarousel" : "";

    return (
        <>
            <Carousel className={carouselClass} indicators={false} controls={controls} fade={fade}>
                {
                    imagesSrc.map((imageSrc, index) => {
                        const bgStyle: React.CSSProperties = {
                            backgroundImage: `url(${imageSrc})`
                        };
                        const imageInterval: number = imageIntervals ? imageIntervals[index] : 4000;

                        return (
                            <Carousel.Item interval={imageInterval} key={`Image-Carousel-Item-${index}`}>
                                {!background &&
                                    <Image
                                        className="d-block w-100"
                                        src={imageSrc}
                                        alt={`Image Carousel Slide ${index}`}
                                    />
                                }
                                {background &&
                                    <div className="backgroundImage d-block w-100" style={bgStyle} />
                                }
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
            {shadow &&
                <Container fluid className='boxshadow p-0' />
            }
        </>
    );
}

export { ImageCarousel };