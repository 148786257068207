import { Container, Image, Row, Col, Table } from 'react-bootstrap';

import { ProjectRoute } from "../index";
import { SummaryProps } from '../../../data';
import { CodeView, StoreButtons, LightboxWrapper } from '../../../components';

import codeFileController from "../../../static/txt/B&B/Menu_Controller.txt";
import codeFileView from "../../../static/txt/B&B/Menu_View.txt";
import codeFileModule from "../../../static/txt/B&B/Menu_Module.txt";

const BedAndBeakfastRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "PC Game (Windows, Mac)",
        software: "Unity, Git, Steamworks, Jira",
        size: "6 (core) + 5 (contract)",
        language: "C#",
        role: "Lead Programmer, Producer, Designer",
        duration: "8 months",
        release: "May 3rd, 2023",
        achievements: 
        [
            "In-Game Art Implementation Runner-up, JoyArt Boston 2024",
            "Featured at PAX East 2024",
            "IMGD Provost Award, WPI Showfest 2024",
            "Excellence in Visual Art Winner, RPI GameFest 2024"
        ]
    }

    const imageFolderPath : String = "/img/Projects/B&B/";
    const backgroundPath : String = "Background/";
    const contentPath : String = "Content/";

    return (
        <ProjectRoute 
            imagesSrc={["MainMenu.jpg", "Gameplay.jpg", "Cinematic.jpg", "Dialogue.jpg", "Postcard.jpg"]
                .map(str => `${imageFolderPath}${backgroundPath}${str}`)}  
            title="Bed and BEAKfast"
            summary={summary}
        >
            <Container fluid>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <div className='video-container'>
                            <iframe title='B&B Trailer' className='px-0 video'
                                src="https://www.youtube.com/embed/A1oioVqJcY4?autoplay=1&mute=1&playlist=A1oioVqJcY4&loop=1"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Col>
                </Row>
                <p>
                    <i>Bed and BEAKfast</i> is a management, cooking, and narrative game, released as a free demo available on Steam for Windows and Mac.
                    Play as Robin and return to your hometown to regrow the bed and breakfast left to you by your late grandfather as you 
                    cook meals, connect with the community, and forge lasting relationships!
                </p>
                <p>
                    As part of my senior capstone at Worcester Polytechnic Institute, I served as lead programmer on a team of 6 students. 
                    Together, we used this opportunity to execute a complete development cycle for a 3-4 hour vertical gameplay slice, 
                    iterating on the project from concept to release.
                </p>
                <p> 
                    As lead programmer, my responsibilities included planning the program architecture, managing a project backlog,
                    defining best practices, organizing tooling, and coordinating the work of peer developers. My individual contributions
                    included developing core game systems such as a save data solution, event scripting tools, inventory and item management, and our custom menu system.
                </p>
                <Container fluid className='px-3 pb-3'>
                    <StoreButtons 
                        steamLink='https://store.steampowered.com/app/2844620/Bed_and_BEAKfast/' 
                    />
                </Container>
            </Container>
            <Container fluid>
                <h2 id="program-architecture" className="py-2">Program Architecture</h2>
                <p>
                    From the onset of development, our vision for the game included many mechanics and systems that we knew would eventually
                    be modified or cut down to accomadate the iterative development process and our strict time constraints. Much of my
                    preproduction work on <i>Bed and BEAKfast</i> involved drafting a plan for the program architecture based on this knowledge,
                    stubbing out the preliminary design requirements for each system, and identifying the dependencies between them.
                </p>
                <Row className='px-0 pb-3'>
                    <Col>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src={`${imageFolderPath}${contentPath}Diagram_ProgramArchitecture.png`} alt="Program Architecture - Diagram" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    This process of mapping namespaces to game systems provided a general overview of the project to guide individual contributors.
                    Self-contained systems could be designed to feature high levels of complexity and interaction within their namespace,
                    while only exposing the necessary public API for other systems to hook into.
                </p>
                <p>
                    For systems such as Inventory, Save Data, and Menu Systems, whose scope and importance shifted throughout development,
                    this approach allowed for continued iteration while minimizing interference with other systems.
                </p>
            </Container>
            <Container fluid>
                <h2 id="menu-system" className="py-2">Menu System</h2>
                <p>
                    A primary example of these shifting design considerations that benefited from our flexible approach was our Menu System.
                    Initially stubbed in as a temporary solution to allow for basic interaction with the game world, early playtests led our team's
                    designers to alter their plans, substituting an overworld style management of ingredients and cooking with full menus.
                    Supporting this functionality in the long-term required a significant rework to this system.
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src={`${imageFolderPath}${contentPath}Gameplay_CookingMenu.jpg`} alt="Cooking Menu" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src={`${imageFolderPath}${contentPath}Gameplay_Menus.gif`} alt="Demo of Game Menus" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    In designing our full Menu System, I aimed to create flexible tooling that could fit our designers' needs for rapid iteration and the reuse of components
                    across different game screens. These requirements yielded a modified <abbr title="Model-View-Controller">MVC</abbr> pattern that made use of <b>modules</b>,
                    reusable components which served to bridge a gap between Controllers and Views' data management needs.
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={5}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src={`${imageFolderPath}${contentPath}Diagram_ModelViewController.png`} alt="MVC - Diagram" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={7}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src={`${imageFolderPath}${contentPath}Diagram_MenuSystem.png`} alt="Menu System - Diagram" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <Table bordered variant='dark'>
                    <tbody>
                        <tr>
                            <td>
                                Model
                            </td>
                            <td>
                                Input and output data for the Menu System were wrapped in lightweight <b>Context</b> objects.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                View
                            </td>
                            <td>
                                Recyclable <b>View</b> classes render data by reading and manipulating model data through a menu's modules.                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Controller
                            </td>
                            <td>
                                <b>Menu</b> controllers interface with external systems, instancing the necessary modules for a given screen with the relevant data.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Module
                            </td>
                            <td>
                                <b>Modules</b> provide common behaviors across a variety of menus, such as data binding, filtering, and player interactions callbacks.
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p>
                    Compared to traditional MVC architecture, this system benefitted from the ability for data and behaviors to pass between Controllers and Views
                    with no knowledge of one another. This allowed us to reuse the same Views across entirely different Menus with minimal code changes, as Views were
                    designed to interact with Modules that each Menu created to their own specification.
                </p>
                <p>
                    To paint an example of this, our Inventory View - a panel the player used for selecting ingredients or foods - was used in a varienty of situations, such as
                    whether the player was cooking food, serving customers, or simply opening their fridge. Each Menu used the same View and Modules to control the passing of data,
                    such as inventory contents, as well as behaviors, such as how many items a player could select. Yet different Menus specified different amounts that could be
                    selected, and different types of objects that were visible. This approach allowed the same View and Module components to be recycled with minimal code overhead.
                </p>
                <CodeView title="Controller and Model" language="c#" file={codeFileController} />
                <CodeView title="View" language="c#" file={codeFileView} />
                <CodeView title="Module" language="c#" file={codeFileModule} />
            </Container>
            <Container fluid>
                <h2 id="save-system" className="py-2">Save Data Solution</h2>
                <p>
                    For <i>Bed and BEAKfast</i>, I developed a distributed save system which facilitated the preservation of state across the game's various systems.
                    A <b>SaveManager</b> provided for file I/O, JSON serialization, saving and loading operations, and debug tooling, protecting higher levels from these intricacies.
                    This focus on a low-level implementation for the core system paired well with an <b>ISaveHandler</b> interface,
                    which empowered individual game components to use minimal code to manage relevant data, implementing a set of standardized methods for loading and saving their information.
                    This implementation provided the team with a number of key features and benefits:
                </p>
                <ul>
                    <li>
                        <b>Modularity:</b> By using the ISaveHandler interface, each game system (e.g., NPCs, inventory) manages its own data. This distributed approach keeps the code organized and modular,
                        allowing new systems to integrate seamlessly.
                    </li>
                    <li>
                        <b>Serialization to JSON:</b> Game state is stored as JSON files, providing greater flexibility and control than Unity's PlayerPrefs save data solution.
                        This allowed for ease of debugging and modification during development, and rapid integration with Steam Cloud for cross-platform data compatibility with Mac and Windows.
                    </li>
                    <li>
                        <b>Automatic Data Propagation:</b> The system saves game state to designated save slots and provides utilities to easily switch between multiple save files.
                        While this feature wasn't utilized in the final release, its addition enabled rapid debugging during development, as saving and loading different files automatically
                        triggered the relevant behaviors on ISaveHandlers in the scene.
                    </li>
                    <li>
                        <b>Editor Support:</b> As indicated above, a focus of this implementation was ease of in-editor testing. Save data was always available to edit via JSON, but tools were also maintained
                        to ensure we were always able to observe and edit save data from within the editor.
                    </li>
                </ul>
                <p>
                    This division of low-level system architecture and interface implementation proved to be especially important in the latter months of development,
                    when a rewrite to our inventory system created complications with serializing Scriptable Objects by reference - an unsupported operation for Unity's
                    implementation of GUIDs, which are generated non-deterministically at runtime. Because of the division of behavior, I was able to rewrite the lower level's
                    serialization algorithm to use custom <b>ReferenceTables</b> to convert Scriptable Object to safe references prior to serialization, injecting a critical step
                    into our Save System with no impact on the ISaveHandlers and their implementations of the system.
                </p>                
            </Container>
        </ProjectRoute>
    );
}

export default BedAndBeakfastRoute;