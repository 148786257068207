import { Container, Image, Row, Col } from 'react-bootstrap';

import { ProjectRoute } from "./index";
import { SummaryProps } from '../../../data';
import { CodeView, LightboxWrapper } from '../../../components';

import codeFile from "../../../static/txt/HospitalPrototype/SelectorWindow.txt";


const HospitalPrototypeRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "Software Development",
        software: "JavaFX, Apache Derby, Jira, Git",
        size: "10",
        language: "Java",
        duration: "7 weeks",
        role: "Lead Software Engineer"
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/HospitalPrototype/Map1.png", "/img/Projects/HospitalPrototype/Database.png", "/img/Projects/HospitalPrototype/MapMini.png", "/img/Projects/HospitalPrototype/Login.png"]}  
            title="Hospital App Prototype"
            summary={summary}
        >
            <Container>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/HospitalPrototype/ServiceRequest.png" alt="Service Request Tool" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/HospitalPrototype/Map2.png" alt="Map Tool" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    This hopsital administrative application prototype was developed for Brigham & Women's Hospital as part of the curriculum for
                    CS 3733: Software Engineering. Within a team of 10 software developers managed with Scrum methodology, the team collected
                    software requirements, managed a product backlog, maintained up-to-date documentation, and delivered a successful prototype 
                    across several weeklong sprints in an intense development setting. 
                </p>
                <p>
                    My role in development as Lead Software Engineer was to facilitate back-end and front-end communication, determine project 
                    direction, and provide support to the entire team. Alongside this, I held the task of single-handedly developing most of the 
                    interactive map component of the prototype. The map - one of the larger components of the project - was to be used for 
                    hospital workers to update room information and the location of relevant items through an intuitive graphical interface. 
                </p>
            </Container>
            <Container fluid>
                <h2 id="scrum" className="py-2">Scrum Development Cycle</h2>
                <Container fluid className="px-0 py-3">
                    <LightboxWrapper>
                        <Image fluid src="/img/Projects/HospitalPrototype/ScrumDevelopmentCycle.png" alt="Scrum Development Cycle" />
                    </LightboxWrapper>
                </Container>
                <p>
                    Given my past experience with Scrum methodologies in software development internship positions, I was chosen by the team to 
                    serve as Lead Software Engineer on the project, and direct the team towards following a proper Scrum Development Cycle. 
                    Through several weeklong sprints, we maintained a product backlog that constantly evolved to fulfill the demands of the 
                    client. This was accomplished through a series of backlog groomings, sprint retrospectives, and daily stand-ups. 
                </p>
            </Container>
            <Container fluid>
                <h2 id="documentation" className="py-2">Software Documention</h2>
                <Row className="px-0 py-3">
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid src="/img/Projects/HospitalPrototype/SequenceDiagram.png" alt="Sequence Diagram" />
                        </LightboxWrapper>
                        <LightboxWrapper>
                            <Image fluid src="/img/Projects/HospitalPrototype/ERD.png" alt="Entity-Relationship Diagram" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6} className='d-flex align-items-center'>
                        <LightboxWrapper>
                            <Image fluid src="/img/Projects/HospitalPrototype/ClassDiagram.png" alt="Class Diagram" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    As part of the project documentation, the team maintained sequence charts, entity-relationship diagrams, and class charts
                    updated on a weekly basis. These tools communicated the requirements towards task completion for junior engineers, while also
                    outlining how the internal systems of the program interacted for purposes of future alterations to the program.
                </p>
                <p>
                    In addition to these weekly documentation tasks, a full 81-page user manual was produced upon successfully delivering the project.
                    This manual outlined every feature of software was to be used for.
                </p>
            </Container>
            <Container fluid>
                <h2 id="map" className="py-2">Interactive Map Component</h2>
                <Row className='d-flex align-items-middle px-0 pb-3'>
                    <LightboxWrapper>
                        <Image fluid className='mb-3 mb-sm-0 p-0' src="/img/Projects/HospitalPrototype/MapDemo.gif" alt="Map Demo" />
                    </LightboxWrapper>
                </Row>
                <p>
                    The interactive map application, one of the central component to the application was developed near singlehandedly by me. This
                    tool allows users to graphically interact with hospital floors for a number of different reasons. Editing, viewing, and 
                    filtering is possible for hospital rooms, medical equipment, service requests, and patients. Each additional system is 
                    toggleable, and when active, adds various new interactable tokens to the map.
                </p>
                <p>
                    Not only does this serve as an effective graphical user interface for managing locations and their relevant items, but I was
                    able to extend the map system into a pop-up model which allows users to directly select locations on the map when selecting
                    them for their service requests. I would later abstract this pop-up modal format to allow my peers to do the same with additional
                    systems, including choosing employees and patients.
                </p>
                <CodeView title="Selector Window (Abstraction)" language="java" file={codeFile} />
            </Container>
        </ProjectRoute>
    );
}

export default HospitalPrototypeRoute;