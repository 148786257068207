import React from 'react';

import { Accordion, Col, Container, Row } from 'react-bootstrap';

import { SummaryProps } from "../../data";

import "./SummaryBox.css";

interface SummaryBoxProps {
    summary: SummaryProps
};

const SummaryBox = ({ 
    summary 
} : SummaryBoxProps): JSX.Element => {
    const isLarge = window.innerWidth >= 992;
    const defaultActiveKey = isLarge ? ['0'] : [];
    
    if (summary) return (
        <Accordion id="summary-dropdown" className='pt-3 px-3' defaultActiveKey={defaultActiveKey}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Project Details</Accordion.Header>
                <Accordion.Body className='p-0'>
                    <Container>
                        <Row className='summaryBox d-flex justify-content-center p-3'>
                            <Col xs={12} lg={6}>
                                <SummaryBoxLine property='Project Type' value={[summary.type ?? '']} />
                                <SummaryBoxLine property='Role' value={[summary.role ?? '']} />
                                <SummaryBoxLine property='Software' value={[summary.software ?? '']} />
                                <SummaryBoxLine property='Language' value={[summary.language ?? '']} />
                            </Col>
                            <Col xs={12} lg={6}>
                                <SummaryBoxLine property='Duration' value={[summary.duration ?? '']} />
                                <SummaryBoxLine property='Team Size' value={[summary.size ?? '']} />
                                <SummaryBoxLine property='Release Date' value={[summary.release ?? '']} />
                                <SummaryBoxLine property='Awards' value={summary.achievements ?? []} forceUseList />
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
    else return <></>;
}

interface SummaryBoxLineProps {
    property: string,
    value: string[],
    forceUseList?: boolean
};

const SummaryBoxLine = ({
    property,
    value,
    forceUseList = false
} : SummaryBoxLineProps): JSX.Element => {
    value = value.filter((item) => item !== '');

    if (value.length === 0) return <></>; 
    else if (value.length === 1 && !forceUseList) return (
        <>
            <p className='lineHeader'>{property}</p>
            <p>{value[0]}</p>
        </>
    );
    else return (
        <>
            <p className='lineHeader'>{property}</p>
            <ul>
                {value.map((item) => <li>{item}</li>)}
            </ul>
        </>
    );
}

export { SummaryBox };