import React from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';

import { ProjectPreview } from "../data";
import { DisplayCard } from '../components';

import "./HomeRoute.css"

import summaries from "../static/json/activeSummaries.json";


const PREVIEW_SUMMARIES: number = 3;

const HomeRoute = (): JSX.Element => {
    // Load Project Summary Data from JSON
    const projectPreviews: ProjectPreview[] = [];
    for (let i = 0; i < PREVIEW_SUMMARIES; i++) {
        projectPreviews.push(summaries.entries[i] as ProjectPreview)
    }

    return (
        <Container fluid className='p-0'>
            <span id='home' />
            <Container fluid className='overlay-wrapper p-0'>
                <Image src="img/Home/Banner.png" alt="name-background" />
                <Container fluid className='overlay-content'>
                    <div className='py-3'>
                        <h1>Nicholas Frangie</h1>
                    </div>
                    <div className='alt-text py-2'>
                        <h1>Software Engineer</h1>
                    </div>
                </Container>
            </Container>
            <Container fluid className='p-3 p-md-5'>
                <Container className='mt-3'>
                    <p>
                        Welcome to my portfolio!
                    </p>
                    <p>
                        I'm Nicholas Frangie, a software engineer with a focus in game development and a background in full-stack web development.
                        Feel free to browse this handmade site as a showcase of some of the cool projects I've recently developed and contributed to!
                    </p>
                </Container>
            </Container>
            <Container fluid className='alt-bg p-3 p-md-5'>
                <Container fluid id='projects'>
                    <Row className='d-flex justify-content-center'>
                        {projectPreviews?.map((projectPreview, index) => {
                            return (
                                <Col xs={12} sm={10} md={8} lg={6} xl={4} key={`display-card-${index}-col`} className='d-flex align-content-center p-0 p-sm-3'>
                                    <DisplayCard key={`display-card-${index}`} projectPreview={projectPreview} />
                                </Col>
                            )
                        })}
                    </Row>
                    <h3 className='d-flex justify-content-center pt-3'>
                        <a href="/projects">
                            View more projects!
                        </a>
                    </h3>
                </Container>
            </Container>
        </Container>
    );
}

export default HomeRoute;