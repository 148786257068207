import { Container, Image, ListGroup, ListGroupItem } from 'react-bootstrap';

import { ProjectPreview } from "../../data";

import "./DisplayCard.css";


const PROJECT_PREFIX: string = "/projects";

interface DisplayCardProps {
    projectPreview: ProjectPreview
};

const DisplayCard = ({
    projectPreview
}: DisplayCardProps): JSX.Element => {
    return (
        <Container fluid>
            <a className='cardLink' href={projectPreview.external ? projectPreview.link : PROJECT_PREFIX + projectPreview.link} target={projectPreview.external ? "_blank" : ""}>
                <Image fluid className='cardImage' src={`img/${projectPreview.imageSrc}`} alt={projectPreview.title} />
                <p className='cardTitle'>{projectPreview.title}</p>
            </a>
            <Container fluid className='tagRow px-3'>
                {projectPreview.tags?.map((tag, index) => {
                    return (
                        <div className={`tag ${tag.type}`} key={`display-card-tag-${index}`}>{tag.desc}</div>
                    )
                })}
            </Container>
            <Container className='px-3'>
                <p>{projectPreview.description}</p>
                <ListGroup className='pb-3 pb-md-0'>
                    {projectPreview.highlights?.map((highlight, index) => {
                        let action: boolean = false;
                        let href: string = "";
                        let target: string = "";

                        if (highlight.link) {
                            if (highlight.type === "internal" && projectPreview.link) {
                                action = true;
                                href = PROJECT_PREFIX + projectPreview.link + highlight.link;
                            } else {
                                action = true;
                                href = highlight.link;
                                target = "_blank";
                            }
                        }

                        return (
                            <ListGroupItem active={false} action={action} href={href} key={`highlight-${index}`} target={target}>
                                {highlight.description}
                            </ListGroupItem>
                        )
                    })}
                </ListGroup>
            </Container>
        </Container>    
    );
}

export { DisplayCard };