import React, { useState } from 'react';

import "./LightboxWrapper.css";

interface LightboxWrapperProps {
    children?: React.ReactNode,
};

const LightboxWrapper = ({
    children
}: LightboxWrapperProps): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string>((children as React.ReactElement).props.src);
    const [imageAlt, setImageAlt] = useState<string>((children as React.ReactElement).props.alt);

    return (
        <>
            <div id='lightbox-wrapped-content' onClick={() => setVisible(true)}>
                {children}
            </div>
            <div className='large-screens-only'>
                <div id="lightbox" onClick={() => setVisible(false)} hidden={!visible}>
                    <img id="lightbox-image" src={imageSrc} alt={imageAlt}></img>
                </div>
            </div>
        </>
    );
}

export { LightboxWrapper };