import { Container, Image, Row, Col } from 'react-bootstrap';

import { ProjectRoute } from "./index";
import { SummaryProps } from '../../../data';
import { CodeView, LightboxWrapper } from '../../../components';

import codeFile from "../../../static/txt/Dragonfly/GravityController.txt";


const DragonflyRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "Game Engine",
        software: "SFML, Git",
        size: "1 (engine), 2 (game)",
        language: "C++",
        duration: "7 weeks",
        role: "Programmer"
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/Dragonfly/GameTitle.gif"]}  
            title="ASCII Engine & Game"
            summary={summary}
        >
            <Container fluid>
                <h2 id="engine" className="py-2">Creating Dragonfly (Game Engine)</h2>
                <p>
                    Dragonfly is an ASCII art game engine developed throughout the course of IMGD 3000: Technical Game Development I. Students in
                    this class develop the game engine from scratch, implementing numerous features on a system level to further their understanding
                    of game engines. Topics such as sprites, animation, audio, input, world management and basic physics are expected in the final 
                    engine; I extended development of my own variant of Dragonfly with custom physics capabilities neccessary for the arcade game 
                    developed within the engine.       
                </p>
            </Container>
            <Container fluid>
                <h2 id="game" className="py-2">Creating Grav Gone (Arcade Game)</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/Dragonfly/Gameplay1.jpg" alt="Gameplay Image" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/Dragonfly/Gameplay.gif" alt="Gameplay Video" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    Grav Gone is a physics-based arcade game where the player survives an endless flight thought gravity-shifting space with nothing
                    but their trusty gun and the recoil its shots provide. 
                </p>
                <p>
                    Designing and programming the game served as a capstone to the course's education in game engine creation and maintainence, 
                    as the gravity manipulation of recoil mechanics of the game required me to add additional physics capabilities to Dragonfly.
                    Some of these unique physics systems are controlled through the game's GravityController, of which, a snippet is accessible
                    below.    
                </p>
                <CodeView title="Gravity Controller" language="c++" file={codeFile} />
                <p>
                    This game implementation and its engine extensions demonstrate a thorough understanding of game engine programming, and 
                    enabled me to work as an assistant supporting students taking the course in future sessions.
                </p>
            </Container>
        </ProjectRoute>
    );
}

export default DragonflyRoute;