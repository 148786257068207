import { Container, Row, Col, Image } from 'react-bootstrap';

import { ProjectRoute } from "./index";
import { SummaryProps } from '../../../data';
import { LightboxWrapper } from '../../../components';


const GottaGoBotRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "PC Game",
        software: "Unreal, Git",
        size: "7",
        language: "Blueprints",
        role: "Programmer, Technical Artist",
        duration: "7 weeks",
        release: "May 3rd, 2023",
        achievements:
        [
            "In-Game Art Implementation Runner-up, JoyArt Boston 2024",
            "Featured at PAX East 2024"
        ]
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/GottaGoBot/ConceptArt1.png", "/img/Projects/GottaGoBot/ConceptArt2.png", 
                "/img/Projects/GottaGoBot/ConceptArt3.png",  "/img/Projects/GottaGoBot/ConceptArt4.png"]}  
            title="Gotta Go-Bot"
            summary={summary}
        >
            <Container fluid>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <div className='video-container'>
                            <iframe title='GGB Trailer' className='px-0 video'
                                src="https://www.youtube.com/embed/uQkBE9wMHmw?autoplay=1&mute=1&playlist=uQkBE9wMHmw&loop=1"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Col>
                </Row>
                <p>
                    Gotta Go-Bot is a third-person adventure platformer game that makes use of energy-consumption mechanics tied to player movement
                    abilities. Players must plan the use of their limited energy to dash, grapple, and jump through multiple levels of enemies, 
                    interactables, and hazards, all the while optimizing the game for speed in the face its puzzle-like level design. Assuming the
                    role of the titular "go-bot," the player explores the abandoned ruins of a much larger robot, in levels that blend together
                    mechanical and environmental design aesthetics.
                </p>
                <p>
                    As part of this project's 7 person team, I worked as a programmer, creating scripts and systems for Enemy AI and many of the game's
                    interactables and hazards. I also worked as a technical artist on the project, supporting the work of our 3-person art as they developed
                    assets which I implemented in-engine alongside making use of Unreal Engine's tools for lighting, materials, and visual effects.
                </p>
                <Container fluid>
                    <iframe title='GGB Itch' className="w-100" src="https://itch.io/embed/2181673?border_width=3&amp;bg_color=030303&amp;fg_color=eeeeee&amp;link_color=FF6666&amp;border_color=2b2b2b">
                        <a href="https://tatedonnelly.itch.io/gotta-go-bot">
                            Gotta Go-Bot by tatedonnelly
                        </a>
                    </iframe>
                </Container>
            </Container>
            <Container fluid>
                <h2 className="py-2">Systems Planning</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/SystemsDiagrams.png" alt="Systems Diagram" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    In preparation for our 7-week long development cycle, the technical team distributed tasks across discrete systems, such that
                    each independent system could be researched, developed, and implemented by a single programmer and later combined to form 
                    the overall project.
                </p>
                <p>
                    As this was many of the team's first times working in Unreal, assigning specializations for programmers to focus on allowed
                    for learning and progress to occur at an accelerated rate, as each teammate has their own niches to fill. My assigned regions for
                    the project were <b>enemy behavior</b>, <b>miscellaneous gameplay interactables and hazards</b>, and <b>technical art tasks</b>. 
                </p>
            </Container>
            <Container fluid>
                <h2 id="enemyai" className="py-2">Scripting Enemy AI</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/BehaviorTree.webp" alt="Behavior Tree" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/AIExample.webp" alt="AIExample" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    My primary task for the Alpha build, a deadline halfway into the game's development, was creating enemies fitting for a platforming game. 
                    Unreal Engine contains many built-in tools for enemy behavior scripting and AI decision-making, which means the largest challenge
                    in completing this task was in learning how to make the most of these tools for the simple enemy behaviors our team desired. 
                    As part of this task, I learned how to work with Unreal's AI Controllers, Behavior Trees, Blackboards, and the Perception System 
                    to make two enemy variants with unique behaviors from a core parent class.  
                </p>
                <p>
                    The core enemy blueprints I created handle the generic behaviors of all enemies, hooking up AI Controllers to Character Movement 
                    components, activating sight perceptions to detect when the target player is in their line of sight, and implementing damage, knockback,
                    and death functionality for the enemy. Since on technical level, these enemies are far more similar than they are different, 
                    this design pattern ensures much of the work is not needlessly replicated. Enemy movement patterns are the largest difference between the
                    two types: while both enemies approach and target the player character when it is identified through their sight perception, their idle 
                    behaviors differ. 
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/RockEnemy.png" alt="Rock Enemy" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/RockEnemyPatrol.png" alt="Rock Enemy Patroling" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    The rock enemy follows a series of patrol nodes as specified by the level designer, moving along a set path in their idle state. This
                    behavior is consistent and predictable, perfectly fitting for the first level of Gotta Go-Bot, so players focus on learning movement 
                    controls with a familiar enemy archetype. 
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/SquirrelEnemyPatrol.png" alt="Squirrel Enemy Patroling" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/SquirrelEnemy.png" alt="Squirrel Enemy" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    The squirrel enemy scurries about a designated patrol area as specified by the level designer, randomly selecting points in the zone, 
                    moving over to them, and waiting for a short delay before choosing a new point to scurry to in their idle state. This behavior 
                    is more random and less easy for players to predict, so we introduce them in Gotta Go-Bot's second level, when the basics of gameplay are
                    already mastered. 
                </p>
                <p>
                    Even though these enemies have different behavior as specified by unique Behavior Trees, the logic of passing information from the 
                    AI Controllers to these Behavior Trees is standardized through the use of a shared Blackboard, allowing for the core enemy class to 
                    handle functionality for both enemy types.
                </p>
            </Container>
            <Container fluid>
                <h2 id="interactables" className="py-2">Creating Interactables & Hazards</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/Interactables.png" alt="Doors, Buttons, Levers" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/Hazards.png" alt="Brambles, Death Planes" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    For the Alpha build, my secondary task consisted of completing various interactables and hazards for their use in-game.
                </p>
                <p>
                    I created a generic interactable system for objects to be paired with any number of switches; whenever all paired switches
                    were activated, an objects' activation method would fire. This system was employed for the use of activating doors with levers 
                    and buttons; although we ended up just using buttons in the final build of the game, the underlying system still allows any 
                    number of different switches to activate interactable objects.
                </p>
                <p>
                    Similarly, I created a reusable hazard component that could apply damage and knockback to the player character from any hitbox; this
                    was useful for making the enemies deal damage to the player, but also was employed to create the brambles in the game, which deal damage 
                    on collision, as well as the hidden death planes scattered throughout the levels.
                </p>
            </Container>
            <Container fluid>
                <h2 id="techart" className="py-2">Technical Art Tasks</h2>
                <p>
                    Working as a technical artist on Gotta Go-Bot included scripting VFX and implementing tools to support the many assets created by the art team.
                    Accomplishing this involved making use of various systems within Unreal Engine, such as materials, animations, lighting, landscape, and foliage.
                </p>
                <h4 className="py-2">Materials</h4>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/MaterialScripting.png" alt="Leaf Material Scripting" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    Creating materials to implement the art team's textures in engine was a simple process; I made several master materials 
                    for the different types of assets, dependent on the needs and context of the texture. These included simple opaque, 
                    translucent, and decal materials which allowed for color, normal, emissive, roughness, metallic, and other maps to be 
                    plugged into material instances for ease of use by artists.
                </p>
                <p>
                    However, there were also many materials I was responsible for creating that weren't simply for plugging-in the art team's 
                    assets. These included materials such as a post-processing material for depth fading, smoke materials for VFX, landscape materials 
                    for blending dirt and grass, and materials for leaves to turn their flat planes into believable shrubbery various features such as
                    billboarding, wind displacement, and subsurface scattering.
                </p>
                <h4 className="py-2">Animations</h4>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/MorphTargets.png" alt="Morph Targets" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/AnimationController.png" alt="Animation Controller" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    A number of objects made use of morph targets, which I used to animate their intended motion in-engine; more complicated 
                    assets such as the player character required an alternative approach. Provided with the rigged models and animations 
                    from the art team, Animation Blueprints were created in Unreal to handle the more complex logic these objects 
                    demanded. By creating the Animation Blueprint for the player character, I was able to implement the character's base 
                    animations and script them to play according to the character's physics logic.
                </p>
                <h4 className="py-2">Lighting</h4>
                <p>
                    Lighting was another important tech art task, as I worked closely with the team's artists to emulate the vibe from their concept 
                    art in-engine. This task combined strategically placing lights within each scene to achieve the desired feel whilst breaking
                    the realism of Unreal's built in lighting systems: an important decision made to achieve the drastically different lighting for 
                    the environments.
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/LightingShot1.png" alt="Lighting Shot 1" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/ConceptArt1.png" alt="Concept Art Comparison 1" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    The first scene, the robot's hand, combined the traditional directional light source of the environment with an artificial spotlight
                    to create a room full of warm greens and yellows mimicking natural lighting. By tweaking the sky light and post processing effects, a
                    good balance was achieved with the room's shadows, allowing the entire room to be navigable while the majority of lighting still came 
                    from the open-palm spotlight. 
                </p>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/ConceptArt2.png" alt="Concept Art Comparison 2" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/LightingShot2.png" alt="Lighting Shot 2" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    The second scene, the robot's forearm, possessed far less opportunity for natural lighting, and therefore relied on a few more tricks.
                    The acid pool at the bottom of the scene, initially a dark black, was given a neon green effect and upwards-facing rectangular light to 
                    provide a gradient lighting effect absent in the initial design concepts, providing a believable main light source for the room. The emissive 
                    materials attached to the mushrooms provided a nice pseudo-glow effect to the foliage, but not nearly enough on their own. Instead, point lights 
                    were strategically placed amid thick clumps of mushrooms to give off the pink and purple glows found throughout the scene.
                </p>
                <h4 className="py-2">Landscape & Foliage</h4>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/LandscapeShot1.png" alt="Landscape Shot 1" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/LandscapeShot2.png" alt="Landscape Shot 2" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    The starting area, when the robot spawns outside of the first level, was looking a little bit sparse, so the team implemented some 
                    quick tricks to fill out this zone!
                </p>
                <p>
                    I handled the landscape sculpting and painting independently. A cliffside behind the player spawn was added to hide the map's edges. 
                    Dirt and grass layers were painted on by using the aformentioned landscape blend material. I also modeled a simple grass foliage 
                    and hooked up Unreal to automically place it ontop of the landscape's grass layer. The grass material itself is unique, blending the 
                    foliage color with the landscape color, dynamic wind lines, and variation patches generated by random perlin noise. 
                </p>
                <p>
                    The art team prepared various trees and bushes for me with the TreeIt tool. Free there, it was a simple matter of applying the 
                    leaf material and painting the outside landscape with foliage.
                </p>
                <h4 className="py-2">VFX</h4>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/CheckpointNiagara.png" alt="Checkpoint Niagara" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GottaGoBot/FogWall.png" alt="Fog Wall" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    All the particle effects in the game are created and implemented by me through Unreal's Niagara Particle System! Some of these VFX
                    are listed below!
                </p>
                <ul>
                    <li><b>Checkpoints:</b> Neon light rings that glow and animate vertically to attract player attention.</li>
                    <li><b>Wind Lines:</b> Wind particles that spawn above fans, rising up to indicate motion.</li>
                    <li><b>Dust Clouds:</b> Footsteps, jumps, and landing all trigger a dust cloud beneath the player character.</li>
                    <li><b>Fog Wall:</b> A wall of smoke used to strategically hide the game's final area, which was cut for time.</li>
                    <li><b>Dash Effect:</b> Green, energy particles that leave a trail as the player dashes, expending one of their energy cells.</li>
                </ul>
            </Container>
        </ProjectRoute>
    );
}

export default GottaGoBotRoute;