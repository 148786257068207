import React from 'react';
import { useEffect, useState } from 'react';

import { Accordion } from 'react-bootstrap';
import Highlight from 'react-highlight';

import "./CodeView.css";


interface CodeViewProps {
    title: string,
    language: string,
    file: string
};

const CodeView = ({
    title,
    language,
    file
}: CodeViewProps): JSX.Element => {
    const [code, setCode] = useState<string>();

    useEffect(() => {
        fetch(file)
        .then(r => r.text())
        .then(r => setCode(r));
    }, [file]);

    return (
        <Accordion flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body className='p-0'>
                    <Highlight className={language}>
                        {code}
                    </Highlight>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export { CodeView };