import { Container, Row, Col, Image } from 'react-bootstrap';

import { ProjectRoute } from "./index";
import { SummaryProps } from '../../../data';
import { CodeView } from "../../../components";

import codeFile1 from "../../../static/txt/Crossroads/FlagManager.txt";
import codeFile2 from "../../../static/txt/Crossroads/SaveSystem.txt";


const CrossroadsRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "Game Jam",
        software: "Unity, Jira, GitHub",
        language: "C#",
        role: "Lead Programmer, Lead Producer",
        duration: "1 month",
        release: "August 16th, 2021"
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/Crossroads/Heresy.jpg", "/img/Projects/Crossroads/Boss1.jpg", "/img/Projects/Crossroads/Limbo.jpg", "/img/Projects/Crossroads/Gluttony.jpg"]}  
            title="Crossroads"
            summary={summary}
        >
            <Container>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/Crossroads/Boss2.png" alt="Boss Fight" />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/Crossroads/Room.gif" alt="Gameplay Room" />
                    </Col>
                </Row>
                <p>
                    Crossroads is an action-adventure metroidvania game set across the circles of Hell. Players must explore multiple levels to 
                    expand their arsenal, opening new routes for them to eventually escape Hell's Crossroads.
                </p>
                <p>
                    During Summer 2021, Crossroads was developed over the course of the month-long Scapegoat Studios program. During this period, 
                    our team of 8 students developed products to meet alpha, beta, and gold milestones; of an initial 4 teams, we were 1 of 2 to 
                    meet these milestones.
                </p>
                <Container fluid>
                    <iframe title='Crossroads Itch' className="w-100" src="https://itch.io/embed/1439091?border_width=3&amp;bg_color=030303&amp;fg_color=eeeeee&amp;link_color=FF6666&amp;border_color=2b2b2b">
                        <a href="https://inkyblots2.itch.io/crossroads">
                            Crossroads by Nicholas Frangie, Dac56, Fish_in_a_Tank
                        </a>
                    </iframe>
                </Container>
            </Container>
            <Container fluid>
                <h2 id="scripting" className="py-2">Scripting Tools</h2>
                <p>
                    I designed and programmed a modular event scripting system that integrated with the game's save system by flagging events. 
                    This process simplified level design for designers implementing events and triggers in the game, while also ensuring that the 
                    world state and player progression was preserved across gameplay.
                </p>
                <CodeView title="Flag Manager" language="c#" file={codeFile1} />
                <p>
                    This system would used in implementing nearly every object that needed state preservation, including collectibles, 
                    checkpoints, doors, and enemy respawning. 
                </p>
            </Container>
            <Container fluid>
                <h2 id="saves" className="py-2">Save System</h2>
                <p>
                    The Save System I created compiled scripting flags and character state into a binary file that could be easily read and written
                    to throughout gameplay sessions.
                </p>
                <CodeView title="Save System" language="c#" file={codeFile2} />
            </Container>
        </ProjectRoute>
    );
}

export default CrossroadsRoute;