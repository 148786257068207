import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { Header, Footer } from "./components";
import { 
    HomeRoute, ProjectsRoute, AboutRoute, 
    GetLowGrandpaRoute, DragonflyRoute, HospitalPrototypeRoute, 
    CrossroadsRoute, GottaGoBotRoute, AliensWantOurMascotRoute,
    BedAndBeakfastRoute
} from "./routes";

import "./index.css";

ReactDOM.render(
    <React.StrictMode>
        <Router basename={process.env.PUBLIC_URL}>
            <Header />
            <Routes>
                {/* Home Page */}
                <Route path="/" element={<HomeRoute />} />

                {/* Project Pages */}
                <Route path="/projects" element={<ProjectsRoute />} />
                <Route path="/projects/aliens-want-our-mascot" element={<AliensWantOurMascotRoute />} />
                <Route path="/projects/bed-and-beakfast" element={<BedAndBeakfastRoute />} />
                <Route path="/projects/dragonfly" element={<DragonflyRoute />} />
                <Route path="/projects/get-low-grandpa" element={<GetLowGrandpaRoute />} />
                <Route path="/projects/gotta-go-bot" element={<GottaGoBotRoute />} />
                <Route path="/projects/hospital-prototype" element={<HospitalPrototypeRoute />} />

                {/* Archived Pages */}
                <Route path="/projects/archived/crossroads" element={<CrossroadsRoute />} />

                {/* About Pages */}
                <Route path="/about" element={<AboutRoute />} />

                {/* Redirect */}
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
            <Footer />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
