import React from 'react';
import { Container, Col, Row, Image, Button } from 'react-bootstrap';

import "./AboutRoute.css"


const AboutRoute = (): JSX.Element => {
    return (
        <>
            <Container fluid className='p-4 p-sm-5 alt-bg'>
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col lg={4} xl={4} className='d-flex justify-content-center p-4 p-md-2'>
                        <Image fluid id='profile-image' src='/img/About/Profile.png' alt='Profile Image' />
                    </Col>
                    <Col lg={8} xl={7} className='p-4'>
                        <p>
                            Hi, I'm Nicholas Frangie!
                        </p>
                        <p>
                            I'm an Associate Software Engineer working at Demiurge Studios. My background in web development helps me build entire websites like this one from scratch!
                        </p>
                        <p>
                            Whether you're browsing my portfolio as part of the hiring process, stumbled upon it by accident, or just want to see what I've been up to, welcome! Feel free to peruse some of my latest creative and programming projects. 
                        </p>
                        <p>
                            As of right now, I am not currently seeking new employment opportunities. That being said, if you have any inquiries or would like to connect, feel free to reach out!
                        </p>
                        <h3 className='d-flex justify-content-center pt-4'>
                            <a href="/projects">
                                View projects!
                            </a>
                        </h3>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='p-3 p-md-5'>
                <Container>
                    <div className='alt-text center'>
                        <h2>Technologies & Skills</h2>
                    </div>
                    <div className='bar center' />
                </Container>
                <Row id='tech-row' className='d-flex justify-content-center mx-sm-3 mx-lg-5'>
                    <Image fluid className='mx-sm-3 mx-lg-4' src="/img/About/Skills/Unity.png" alt='Unity Game Engine'/>
                    <Image fluid className='mx-sm-3 mx-lg-4' src="/img/About/Skills/Unreal.png" alt='Unreal Game Engine'/>
                    <Image fluid className='mx-sm-3 mx-lg-4' src="/img/About/Skills/GameMaker.png" alt='GameMaker Game Engine'/>
                    <Image fluid className='mx-sm-3 mx-lg-4' src="/img/About/Skills/React.png" alt='React'/>
                </Row>
                <Row id='skill-row' className='d-flex justify-content-center mx-sm-3 mx-lg-5'>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/C.png" alt='C'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/CSharp.png" alt='C#'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/C++.png" alt='C++'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/Java.png" alt='Java'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/JavaScript.png" alt='JavaScript'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/TypeScript.png" alt='TypeScript'/>
                    <Image fluid className='px-1 px-lg-2 mx-lg-2' src="/img/About/Skills/Python.png" alt='Python'/>
                </Row>
            </Container>
            <Container fluid className='p-3 p-md-5 alt-bg'>
                <Container>
                    <div className='alt-text center'>
                        <h2>Resume</h2>
                    </div>
                    <div className='bar center' />
                </Container>
                <Container id="resume-context" className='d-flex align-items-center justify-content-center'>
                    <a href='/pdf/Nicholas-Frangie-Resume.pdf' target='_blank'>
                        <Button className='text-nowrap'>
                            Full View
                        </Button>
                    </a>
                    <a href='/pdf/Nicholas-Frangie-Resume.pdf' download="Nicholas-Frangie-Resume.pdf">
                        <Button className='text-nowrap'>
                            Download
                        </Button>
                    </a>
                    <a href='https://www.linkedin.com/in/nicholas-frangie/' target='_blank'>
                        <Button className='text-nowrap'>
                            LinkedIn
                        </Button>
                    </a>
                </Container>
                <Row>
                    <iframe id='resume-view' className='pt-3' src='/pdf/Nicholas-Frangie-Resume.pdf' title='Resume Viewer'/>
                </Row>
            </Container>
        </>
    );
}

export default AboutRoute;