import { Container, Image, Row, Col } from 'react-bootstrap';

import { ProjectRoute } from "../index";
import { SummaryProps } from '../../../data';
import { CodeView, StoreButtons, LightboxWrapper } from '../../../components';

import codeFile1 from "../../../static/txt/AWOM/BoardCell.txt";
import codeFile2 from "../../../static/txt/AWOM/CSVReader.txt";


const AliensWantOurMascotRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "iOS/Android Game",
        software: "Unity, DevToDev, Plastic SCM",
        size: "6 (core) + 2 (part-time)",
        language: "C#",
        role: "Lead Programmer, Tech Director",
        duration: "3 months",
        release: "August 4th, 2023",
        achievements: 
        [
            "In-Game Art Implementation Winner, JoyArt Boston 2024",
            "Featured at PAX East 2024",
            "Featured at RPI GameFest 2024"
        ]
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/AWOM/FeatureGraphic.png", "/img/Projects/AWOM/Combat.png",
            "/img/Projects/AWOM/LevelSelect.png", "/img/Projects/AWOM/Whistle.png"]}  
            title="Aliens Want Our Mascot?!"
            summary={summary}
        >
            <Container fluid>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <div className='video-container'>
                            <iframe title='AWOM Trailer' className='px-0 video'
                                src="https://www.youtube.com/embed/UWd3ILvXg7o?autoplay=1&mute=1&playlist=UWd3ILvXg7o&loop=1"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Col>
                </Row>
                <p>
                    <i>Aliens Want Our Mascot?!</i> is a tower defense simulator released on iOS and Android platforms. Band together
                    with your favorite high school archetypes to defend Bovine High's school mascot from an alien abduction!
                </p>
                <p>
                    As part of MassDigi's 2023 Summer Innovation Program, I worked as lead programmer on a team of 6 students to develop a
                    game from concept to release within 3 months. This process involved forming the game concept, prototyping, developing, and
                    iterating upon the product across a number of milestones as we prepared for release.
                </p>
                <Container fluid className='px-3 pb-3'>
                    <StoreButtons 
                        appleLink='https://apps.apple.com/us/app/aliens-want-our-mascot/id6450372689?uo=2' 
                        googleLink='https://play.google.com/store/apps/details?id=com.MassDiGI.TeamFriedDough' 
                    />
                </Container>
            </Container>
            <Container fluid>
                <h2 id="planning" className="py-2">Systems Planning</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/AWOM/SystemsDiagrams.png" alt="Namespace Diagram" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    As lead programmer on the project, part of my preproduction responsibilities entailed a thorough mapping of the game's systems.
                    My favorite tool for doing so is creating a namespace diagram, which provides a high-level visualization of the relationships 
                    between related systems in C#, and helps tremendously when creating modular code amongst a team of programmers.
                </p>
                <p>
                    The namespace diagram above reflects the final state of the game's systems. Tweaks to the original diagram were made throughout
                    production as scope reductions, feature redesigns, and regular code refactors were made. In all cases, care was taken to 
                    maintain this diagram and keep its documentation up-to-date.
                </p>
            </Container>
            <Container fluid>
                <h2 id="combat" className="py-2">Combat Programming</h2>
                <Container fluid className="px-0 py-3">
                    <LightboxWrapper>
                        <Image fluid src="/img/Projects/AWOM/PrefabEditing.png" alt="Prefab Editing" />
                    </LightboxWrapper>
                </Container>
                <p>
                    The combat namespace is the largest and most complex of the game's systems, which is why it is the only namespace broken into
                    sub-namespaces in the original planning for the game. It is responsible for handling the game's core gameplay loop, which includes
                    the behaviors, actions, and interactions of all the game's units on the battlefield.
                </p>
                <p>
                    To keep this code as modular as possible, its features are layered atop one-another in sub-namespaces. On the lowest level, the
                    Combat.Board namespaces operates to provide the functionality of the battlefield and its cells. The Board scripts operate without
                    knowledge of units or their behaviors, and are only concerned with the state of the battlefield. This task is accomplished by the
                    use of interfaces and events, as depicted in the attached snippet:
                </p>
                <CodeView title="Board Cell" language="c#" file={codeFile1} />
                <p>
                    Built a layer above the Combat.Board namespace is the Combat.Unit namespace, which provides the functionality for the units, their
                    health system, hit detection, and stats. This layer is responsible for the management of units, but not what they do nor what is done
                    to them.
                </p>
                <p>
                    The highest layers of the Combat namespace are the Combat.Behavior and Combat.Power namespaces, which provide the functionality
                    built on top of everything else. Behavior scripts activate active and passive effects of a Unit; by separating this out from the
                    Unit namespace, we can easily modify behaviors without breaking the core functionality of units. Power scripts are responsible
                    for those things built ontop of Units but not directly related to them, such as projectiles, collectibles, and invokable actions.
                </p>
                <p>
                    By layering the Combat namespace in this way, a modular system is created that allows for developers to easily modify
                    implementations on one layer without effecting the rest of the system. This design philosophy is applied to all of the game through
                    the Namespace Diagram, but was most important to the Combat namespace due to its complexity and tendency to become an interwoven
                    nightmare without a proper design philosophy.
                </p>
            </Container>
            <Container fluid>
                <h2 id="tools" className="py-2">Design Tools</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/AWOM/CSVImportSpreadsheet.png" alt="CSV Import - Spreadsheet" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/AWOM/CSVImportEngine.png" alt="CSV Import - Engine" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    As part of the game's development, I created several tools to assist the design team in implementing content and
                    balancing the game. These tools were designed to be as user-friendly as possible, exposing the necessary parameters
                    for designers to tweak and test their content without needing to touch the codebase. One such feature that exemplifies
                    this philosophy is the spawn scripting system for level data.
                </p>
                <p>
                    Since our level designers preferred working in spreadsheets, I created a simple CSV abstraction that would provide a 
                    framework for parsing and generating data from any CSV file. This abstraction simplified the task of writing future CSV
                    tools by allowing our developers to write a DataProcessor class that would handle the processing for any type of data, without
                    needing to worry about how it interacts with the CSV file.
                </p>
                <CodeView title="CSV Reader (Abstraction)" language="c#" file={codeFile2} />
                <p>
                    With a custom Unity Inspector widget, designers could easily import CSVs of enemy spawns for each level directly into
                    Scriptable Objects in engine. As such, I was able to provide the design team with tools that supported their preferred
                    workflow, empowering them and their ability to overdeliver on the projected number of levels for our game.
                </p>
            </Container>
            <Container fluid>
                <h2 id="tech" className="py-2">Technical Director Tasks</h2>
                <Container fluid className="px-0 py-3">
                    <LightboxWrapper>
                        <Image fluid src="/img/Projects/AWOM/BuildPipeline.png" alt="Build Pipeline Graphic" />
                    </LightboxWrapper>
                </Container>
                <p>
                    As Tech Director for MassDigi's 2023 Summer Innovation Program, I was responsible for managing build pipelines
                    for 5 teams related to the program, as well as coordinating hardware for a number of our showcase events.
                </p>
                <p>
                    By applying the knowledge and familiarity with Unity Cloud Build I had gained from <b>Get Low, Grandpa!</b> for
                    iOS and Android applications, I was able to implement and expand on MassDigi's pre-established pipeline for the
                    program's project teams.
                </p>
                <p>
                    My contributions included new options to the company's build pipeline and updating some of its software dependencies:
                </p>
                <ul>
                    <li>
                        Automated Android builds with Fastlane, streamlining the iOS and Android build processes to the same system.
                    </li>
                    <li>
                        Investigated and utilized Ad-Hoc Distribution Profiles for internal iOS prototyping.
                    </li>
                    <li>
                        Updated DevToDev SDK to a modern version compatible with build pipeline.
                    </li>
                </ul>
            </Container>
        </ProjectRoute>
    );
}

export default AliensWantOurMascotRoute;