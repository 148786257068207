import { Col, Container, Row } from 'react-bootstrap';

import { SummaryProps } from '../../data';
import { SummaryBox, ImageCarousel } from '../../components';

import "./ProjectRoute.css"


interface ProjectRouteProps {
    imagesSrc: string[],
    imageIntervals?: number[],
    title: string,
    summary?: SummaryProps,
    children: React.ReactNode
};

const ProjectRoute = ({
    imagesSrc,
    imageIntervals,
    title,
    summary,
    children
}: ProjectRouteProps): JSX.Element => {
    return (
        <Container fluid className='p-0'>
            <Container fluid className='p-0 background-container'>
                <ImageCarousel imagesSrc={imagesSrc} imageIntervals={imageIntervals} background={true} fade={true} controls={false} shadow={true} />
            </Container> 
            <Container fluid className='content-container'>
                <Row className='d-flex justify-content-center'>
                    <Col xs={11} md={8} lg={7} xl={6} className='content-box align-content-center p-0'>
                        <Container fluid className='p-4'>
                            {/* Title */}
                            <h1 id="project-title" className='center'>{title}</h1>
                        
                            {/* Summary Box */}
                            {summary && 
                                <SummaryBox summary={summary} />
                            }
                                        
                            {/* Content */}
                            {children}
                        </Container>
                    </Col> 
                </Row>
            </Container> 
        </Container>
    );
}

export { ProjectRoute };
export type { ProjectRouteProps };