import { Container, Image, Row, Col } from 'react-bootstrap';

import { ProjectRoute } from "./index";
import { SummaryProps } from '../../../data';
import { StoreButtons, LightboxWrapper } from '../../../components';


const GetLowGrandpaRoute = (): JSX.Element => {
    const summary: SummaryProps = {
        type: "iOS/Android Game (Post-launch)",
        software: "Unity, DevToDev, Plastic SCM",
        language: "C#",
        role: "Programmer, Producer",
        duration: "9 months",
        release: "August 1st, 2022",
        achievements:
        [
            "Featured at PAX East 2023"
        ]
    }

    return (
        <ProjectRoute 
            imagesSrc={["/img/Projects/GetLowGrandpa/AnimatedClip.gif"]}  
            title="Get Low, Grandpa!"
            summary={summary}
        >
            <Container fluid>
                <h2 className="py-2">About</h2>
                <Row className='px-0 pb-3'>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GetLowGrandpa/Gameplay.jpg" alt="Gameplay" />
                        </LightboxWrapper>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LightboxWrapper>
                            <Image fluid className='mb-3 mb-sm-0' src="/img/Projects/GetLowGrandpa/Boogie.gif" alt="Boogie Time" />
                        </LightboxWrapper>
                    </Col>
                </Row>
                <p>
                    <i>Get Low, Grandpa!</i> is an endless runner iOS/Android game developed by a team of students as part of MassDigi's Summer Innovation 
                    Program.       
                </p>
                <p>
                    As part of MassDigi's Digi Studios program, I joined a team of students who worked on continued development of the published 
                    game. The studio program was an opportunity for the team to self-determine content updates and support for an in-production 
                    title, and required that we identify objectives to polish the game and extend its core systems.
                </p>
                <Container fluid className='px-3 pb-3'>
                    <StoreButtons 
                        appleLink='https://apps.apple.com/iq/app/get-low-grandpa/id1630471330' 
                        googleLink='https://play.google.com/store/apps/details?id=com.MassDiGI.TeamBatura&hl=en_US&gl=US&pli=1'
                    />
                </Container>
            </Container>
            <Container fluid>
                <h2 id="collectibles" className="py-2">Optimizing Collectibles System</h2>
                <p>
                    As a programmer on continued development, one of my primary objectives involved refactoring the codebase to allow for easier 
                    inclusion of content and features for systems that were hard-coded by the original team.
                </p>
                <p>
                    The game's original Collectibles System was inefficent and didn't make use of recommended object-orientated programming
                    practices: the logic for all different type of pickups were handled within the player character script using Unity's tag
                    system to designate every different collectible. Refactoring this bloated script to a simple inheritance pattern simplified 
                    the codebase, eliminating redundancies to reduce the size of the player character script by 47%. This optimization made it 
                    easier for us to scale the original system to include new power-ups and collectibles!
                </p>
            </Container>
            <Container fluid>
                <h2 id="building" className="py-2">Managing Build Pipeline</h2>
                <Container fluid className="px-0 py-3">
                    <LightboxWrapper>
                        <Image fluid src="/img/Projects/GetLowGrandpa/BuildPipeline.png" alt="Build Pipeline Graphic" />
                    </LightboxWrapper>
                </Container>
                <p>
                    Serving as the buildmaster for new versions of Get Low Grandpa, I became proficient with a suite of technologies and services
                    employed in the game's build pipeline.
                </p>
                <p>
                    Building through the Unity Dashboard and Plastic SCM integration, I was responsible for generating Fastlane tokens and managing
                    IOS releases with TestFlight and the App Store; likewise, I created .aabs and controlled the release of new Android versions
                    through the Google Play Console.
                </p>
            </Container>
            <Container fluid>
                <h2 id="analytics" className="py-2">Analytics Collection Overhaul</h2>
                <p>
                    The designers working on Get Low Grandpa planned to use analytics collected from players to make decisions regarding difficulty
                    balancing and tweaks to obstacle variants. However, the original analytics hooks in place weren't working to get us this data.
                    One of my first tasks on the project was re-evaluating our DevToDev integration to identify and correct the bugs preventing the
                    design team from receiving the necessary data; this lead to an overhaul of the analytics collection systems that eliminated its
                    errors and reassessed how user analytics were collected. 
                </p>
            </Container>
        </ProjectRoute>
    );
}

export default GetLowGrandpaRoute;