import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import { ProjectPreview } from "../data";
import { DisplayCard } from '../components';

import "./ProjectsRoute.css"

import summaries from "../static/json/activeSummaries.json";


const ProjectsRoute = (): JSX.Element => {
    // Load Project Summary Data from JSON
    const projectPreviews: ProjectPreview[] = [];
    for (let i = 0; i < summaries.count; i++) {
        projectPreviews.push(summaries.entries[i] as ProjectPreview)
    }

    return (
        <Container fluid className='alt-bg p-3 p-md-5'>
            <Container fluid id='projects'>
                <div className='alt-text center'>
                    <h2>Projects</h2>
                </div>
                <div className='bar center' />
                <Row className='d-flex justify-content-center'>
                    {projectPreviews?.map((projectPreview, index) => {
                        return (
                            <Col xs={12} sm={10} md={8} lg={6} xl={4} key={`display-card-${index}-col`} className='d-flex align-content-center p-0 p-sm-3'>
                                <DisplayCard key={`display-card-${index}`} projectPreview={projectPreview} />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </Container>
    );
}

export default ProjectsRoute;