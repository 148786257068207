import React from 'react';

import { Image, Row } from 'react-bootstrap';

import "./StoreButtons.css";


interface StoreButtonsProps {
    appleLink?: string,
    googleLink?: string,
    steamLink?: string
};

const StoreButtons = ({
    appleLink,
    googleLink,
    steamLink
}: StoreButtonsProps): JSX.Element => {
    return (
        <Row id='cardLinkRow'>
            {appleLink &&
                <a href={appleLink} rel="noreferrer" target="_blank" className="storeLink p-0">
                    <Image fluid id="appleStore" className='storeImage' src="/img/Store/AppStore.png" />
                </a>
            }
            {googleLink &&
                <a href={googleLink} rel="noreferrer" target="_blank" className="storeLink p-0">
                    <Image fluid id="googleStore" className='storeImage' src="/img/Store/GooglePlayStore.png" />
                </a>
            }
            {steamLink &&
                <a href={steamLink} rel="noreferrer" target="_blank" className="storeLink p-0">
                    <Image fluid id="steamStore" className='storeImage' src="/img/Store/SteamStore.png" />
                </a>
            }
        </Row>
    );
}

export { StoreButtons };