import { useState } from "react";

import { HashLink as Link } from 'react-router-hash-link';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import "./Header.css";


const Header = (): JSX.Element => {
    const [expanded, setExpanded] = useState(false);

    const onClick = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    }

    return (
        <>
            <Navbar sticky="top" className="navbar sticky" variant="dark" expand="sm" expanded={expanded}>
                <Container id="header" fluid>
                    <Navbar.Brand>
                        <span id="header-title">Nicholas Frangie</span>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="navbar-toggable" className="justify-content-end">
                        <Nav>
                            <Nav.Link as={Link} to="/" onClick={onClick}>Home</Nav.Link>
                            <Nav.Link as={Link} to="/projects" onClick={onClick}>Projects</Nav.Link>
                            <Nav.Link as={Link} to="/about" onClick={onClick}>About</Nav.Link>
                            <Nav.Link as={Link} to="/pdf/Nicholas-Frangie-Resume.pdf" target="_blank" onClick={onClick}>Resume</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <span className="navbar-buffer" />
        </>

    );
}

export { Header };